@import './reset.css';

html, body {
    background: #333333;
    color: #FFFFFF;
}

#app {
}

.btnradius {
border-radius: 25px !important;
}
.wallet-adapter-dropdown {
margin-right: 10px;
}
